<template>
  <section class="main">
    <div class="main__center">
      <h1 class="main__center__title">Wait ! your order in progress.</h1>
      <h2 class="main__center__subtitle">
        Lorem ipsum dolor sit amet, consectetur adipiscing
      </h2>
      <h2 class="main__center__subtitle--mobile">
        Lorem ipsum dolor sit amet, consectetur
      </h2>
      <nav class="main__center__nav">
        <button class="main__center__nav__button">
          <div class="main__center__nav__button__check">
            <img
              src="../assets/check2.svg"
              alt="check"
              class="main__center__nav__button__check__icon"
            />
          </div>
          <div class="main__center__nav__button__text">
            Step 1 : Cart Review
          </div>
          <div class="main__center__nav__button__text--mobile">Cart Review</div>
        </button>
        <button class="main__center__nav__button">
          <div class="main__center__nav__button__check">
            <img
              src="../assets/check2.svg"
              alt="check"
              class="main__center__nav__button__check__icon"
            />
          </div>
          <div class="main__center__nav__button__text">Step 2 : Checkout</div>
          <div class="main__center__nav__button__text--mobile">Checkout</div>
        </button>
        <button
          class="main__center__nav__button main__center__nav__button--current"
        >
          <div
            class="main__center__nav__button__check main__center__nav__button__check--current"
          >
            3
          </div>
          <div
            class="main__center__nav__button__text main__center__nav__button__text--current"
          >
            Step 3 : Special Offer
          </div>
          <div
            class="main__center__nav__button__text--mobile main__center__nav__button__text--current"
          >
            Special Offer
          </div>
        </button>
        <button class="main__center__nav__button">
          <div
            class="main__center__nav__button__check main__center__nav__button__check--not"
          >
            4
          </div>
          <div class="main__center__nav__button__text">
            Step 4 : Confirmation
          </div>
          <div class="main__center__nav__button__text--mobile">
            Confirmation
          </div>
        </button>
      </nav>
      <h3 class="main__center__grid__right__title--mob">
        <span class="main__center__grid__right__title--blue"
          >ONE TIME ONLY</span
        >
        special price for 6 extra Clarifion for only
        <span class="main__center__grid__right__title--blue">$14 each</span>
        ($84.00 total!)
      </h3>
      <div class="main__center__grid">
        <div class="main__center__grid__left">
          <div class="main__center__grid__left__img-box">
            <img
              src="../assets/phone.jpg"
              alt="cellphone"
              class="main__center__grid__left__img-box__img"
            />
            <img
              src="../assets/phone-mob.png"
              alt="cellphone"
              class="main__center__grid__left__img-box__img--mob"
            />
          </div>
          <div class="main__center__grid__left__comment">
            <div class="main__center__grid__left__comment__info">
              <div class="main__center__grid__left__comment__info__img-box">
                <img
                  src="../assets/person.jpg"
                  alt="person"
                  class="main__center__grid__left__comment__info__img-box__img"
                />
              </div>
              <div class="main__center__grid__left__comment__info__data-box">
                <div
                  class="main__center__grid__left__comment__info__data-box__classification"
                >
                  <img
                    src="../assets/star.svg"
                    alt="star"
                    class="main__center__grid__left__comment__info__data-box__classification__star"
                  />
                  <img
                    src="../assets/star.svg"
                    alt="star"
                    class="main__center__grid__left__comment__info__data-box__classification__star"
                  />
                  <img
                    src="../assets/star.svg"
                    alt="star"
                    class="main__center__grid__left__comment__info__data-box__classification__star"
                  />
                  <img
                    src="../assets/star.svg"
                    alt="star"
                    class="main__center__grid__left__comment__info__data-box__classification__star"
                  />
                  <img
                    src="../assets/star.svg"
                    alt="star"
                    class="main__center__grid__left__comment__info__data-box__classification__star"
                  />
                </div>
                <div
                  class="main__center__grid__left__comment__info__data-box__texts"
                >
                  <div
                    class="main__center__grid__left__comment__info__data-box__texts__name"
                  >
                    Ken T.
                  </div>
                  <div
                    class="main__center__grid__left__comment__info__data-box__texts__verified"
                  >
                    <img
                      src="../assets/greencheck.svg"
                      alt="check"
                      class="main__center__grid__left__comment__info__data-box__texts__verified__icon"
                    />
                    <div
                      class="main__center__grid__left__comment__info__data-box__texts__verified__text"
                    >
                      Verified Customer
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main__center__grid__left__comment__content">
              “As soon as the Clarifions arrived I put one in my bedroom. This
              was late in the afternoon. When I went to the bedroom in the
              evening it smelled clean. When I went to bed I felt I could
              breathe better. Wonderful.”
            </div>
          </div>
        </div>
        <div class="main__center__grid__right">
          <h3 class="main__center__grid__right__title">
            <span class="main__center__grid__right__title--blue"
              >ONE TIME ONLY</span
            >
            special price for 6 extra Clarifion for only
            <span class="main__center__grid__right__title--blue">$14 each</span>
            ($84.00 total!)
          </h3>
          <div class="main__center__grid__right__specifications">
            <div class="main__center__grid__right__specifications__img-box">
              <img
                src="../assets/clarifion-small.jpg"
                alt="clarifion"
                class="main__center__grid__right__specifications__img-box__img"
              />
            </div>

            <div class="main__center__grid__right__specifications__texts">
              <div
                class="main__center__grid__right__specifications__texts__titles"
              >
                <h4
                  class="main__center__grid__right__specifications__texts__titles__title"
                >
                  Clarifion Air Ionizer
                </h4>
                <div
                  class="main__center__grid__right__specifications__texts__titles__prices"
                >
                  <div
                    class="main__center__grid__right__specifications__texts__titles__prices__gray"
                  >
                    $180
                  </div>
                  <div
                    class="main__center__grid__right__specifications__texts__titles__prices__blue"
                  >
                    $84
                  </div>
                </div>
              </div>
              <div
                class="main__center__grid__right__specifications__texts__stars"
              >
                <img
                  src="../assets/bigstar.svg"
                  alt="star"
                  class="main__center__grid__right__specifications__texts__stars__star"
                />
                <img
                  src="../assets/bigstar.svg"
                  alt="star"
                  class="main__center__grid__right__specifications__texts__stars__star"
                />
                <img
                  src="../assets/bigstar.svg"
                  alt="star"
                  class="main__center__grid__right__specifications__texts__stars__star"
                />
                <img
                  src="../assets/bigstar.svg"
                  alt="star"
                  class="main__center__grid__right__specifications__texts__stars__star"
                />
                <img
                  src="../assets/bigstar.svg"
                  alt="star"
                  class="main__center__grid__right__specifications__texts__stars__star"
                />
              </div>
              <div
                class="main__center__grid__right__specifications__texts__stock"
              >
                <img
                  src="../assets/circle.svg"
                  alt="stock"
                  class="main__center__grid__right__specifications__texts__stock__icon"
                />
                <div
                  class="main__center__grid__right__specifications__texts__stock__text"
                >
                  12 left in Stock
                </div>
              </div>
              <div
                class="main__center__grid__right__specifications__texts__paragraph"
              >
                Simply plug a Clarifion into any standard outlet and replace
                bulky, expensive air purifiers with a simple.
              </div>
            </div>
          </div>
          <div
            class="main__center__grid__right__specifications__texts__paragraph--mobile"
          >
            Simply plug a Clarifion into any standard outlet and replace bulky,
            expensive air purifiers with a simple.
          </div>
          <div class="main__center__grid__right__caracteristics">
            <div class="main__center__grid__right__caracteristics__item">
              <img
                src="../assets/bluecheck.svg"
                alt="check"
                class="main__center__grid__right__caracteristics__item__check"
              />
              <div
                class="main__center__grid__right__caracteristics__item__text"
              >
                Negative Ion Technology may <b>help with allergens</b>
              </div>
            </div>
            <div class="main__center__grid__right__caracteristics__item">
              <img
                src="../assets/bluecheck.svg"
                alt="check"
                class="main__center__grid__right__caracteristics__item__check"
              />
              <div
                class="main__center__grid__right__caracteristics__item__text"
              >
                Designed for <b>air rejuvenation</b>
              </div>
            </div>
            <div class="main__center__grid__right__caracteristics__item">
              <img
                src="../assets/bluecheck.svg"
                alt="check"
                class="main__center__grid__right__caracteristics__item__check"
              />
              <div
                class="main__center__grid__right__caracteristics__item__text"
              >
                <b>Perfect for every room</b> in all types of places.
              </div>
            </div>
          </div>
          <div class="main__center__grid__right__discount">
            <div class="main__center__grid__right__discount__circle">
              <img
                src="../assets/percent.svg"
                alt="%"
                class="main__center__grid__right__discount__circle__icon"
              />
            </div>
            <div class="main__center__grid__right__discount__text">
              Save
              <span class="main__center__grid__right__discount__text--blue"
                >53%</span
              >
              and get
              <span class="main__center__grid__right__discount__text--blue"
                >6 extra Clarifision</span
              >
              for only
              <span class="main__center__grid__right__discount__text--blue"
                >$14 Each</span
              >.
            </div>
          </div>
          <a href="/#" class="main__center__grid__right__link">
            <div class="main__center__grid__right__link__text">
              Yes - Claim my discount
            </div>
            <img
              src="../assets/arrow.svg"
              alt="arrow"
              class="main__center__grid__right__link__icon"
            />
          </a>
          <div class="main__center__grid__right__payment">
            <div class="main__center__grid__right__payment__text">
              Free shipping
            </div>
            <div class="main__center__grid__right__payment__separator"></div>
            <div class="main__center__grid__right__payment__center">
              <img
                src="../assets/locked.svg"
                alt="lock"
                class="main__center__grid__right__payment__center__icon"
              />
              <div class="main__center__grid__right__payment__text">
                Secure 256-bit SSL encryption.
              </div>
            </div>
            <div class="main__center__grid__right__payment__separator"></div>
            <div class="main__center__grid__right__payment__icons">
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p1.jpg"
                  alt="visa"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p2.jpg"
                  alt="pay"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p3.jpg"
                  alt="paypal"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p4.jpg"
                  alt="mastercard"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p5.jpg"
                  alt="google"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p6.jpg"
                  alt="apple"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
              <div class="main__center__grid__right__payment__icons__icon">
                <img
                  src="../assets/p7.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment__icons__icon__img"
                />
              </div>
            </div>
          </div>

          <div class="main__center__grid__right__payment--mobile">
            <div class="main__center__grid__right__payment--mobile__top">
              <div
                class="main__center__grid__right__payment--mobile__top__text"
              >
                Free shipping
              </div>
              <div
                class="main__center__grid__right__payment--mobile__top__separator"
              ></div>
              <div
                class="main__center__grid__right__payment--mobile__top__right"
              >
                <img
                  src="../assets/locked.svg"
                  alt="locked"
                  class="main__center__grid__right__payment--mobile__top__right__icon"
                />
                <div
                  class="main__center__grid__right__payment--mobile__top__text"
                >
                  Secure 256-bit SSL encryption
                </div>
              </div>
            </div>
            <div
              class="main__center__grid__right__payment--mobile__separator"
            ></div>
            <div class="main__center__grid__right__payment--mobile__bottom">
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p1.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p2.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p3.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p4.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p5.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p6.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
              <div
                class="main__center__grid__right__payment--mobile__bottom__icon"
              >
                <img
                  src="../assets/p7.jpg"
                  alt="amex"
                  class="main__center__grid__right__payment--mobile__bottom__icon__img"
                />
              </div>
            </div>
          </div>
          <a href="/#" class="main__center__grid__right__reject"
            >No thanks, I don’t want this.</a
          >
          <div class="main__center__grid__right__advise">
            <div class="main__center__grid__right__advise__img-box">
              <img
                src="../assets/guarantee.png"
                alt="guarantee"
                class="main__center__grid__right__advise__img-box__img"
              />
            </div>
            <div class="main__center__grid__right__advise__text">
              If you are not completely thrilled with your Clarifion - We have a
              <b>30 day satisfaction guarantee.</b> Please refer to our return
              policy at the bottom of the page for more details. Happy Shopping!
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style scoped>
.main {
  width: 100%;
}
.main__center {
  width: 100%;
  max-width: 1920px;
  padding-left: 127px;
  padding-right: 127px;
  margin: 0 auto;
}
.main__center__title {
  color: #000;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 48px */
  text-transform: capitalize;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 24px;
}

.main__center__subtitle {
  color: #4d5254;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  text-transform: capitalize;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 60px;
}
.main__center__subtitle--mobile {
  display: none;
}
.main__center__nav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 17px;
}
.main__center__nav__button {
  display: flex;
  align-items: center;
  gap: 20px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.main__center__nav__button__check {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #85bf55;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__center__nav__button__check--current {
  background-color: #2c7ef8;
  color: #fff;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 22px */
  text-transform: capitalize;
}

.main__center__nav__button__check--not {
  background-color: transparent;
  color: #2c7ef8;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 22px */
  text-transform: capitalize;
  border: 2px solid #2c7ef8;
}

.main__center__nav__button__text {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 22px */
  text-transform: capitalize;
}

.main__center__nav__button__text--mobile {
  display: none;
}

.main__center__nav__button__text--current {
  font-weight: 700;
}

.main__center__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 40px;
  border-radius: 10px;
  background: #fafafa;
  margin-bottom: 84px;
}
.main__center__grid__left {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.main__center__grid__left__img-box {
  width: 100%;
  height: 591px;
  position: relative;
  max-width: 575px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
}

.main__center__grid__left__img-box__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.main__center__grid__left__img-box__img--mob {
  display: none;
}

.main__center__grid__left__comment {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
}
.main__center__grid__left__comment__info {
  display: flex;
  gap: 13px;
}
.main__center__grid__left__comment__info__img-box {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.main__center__grid__left__comment__info__img-box__img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: 50% 50%;
}
.main__center__grid__left__comment__info__data-box {
  display: flex;
  flex-direction: column;
  gap: 7.35px;
}
.main__center__grid__left__comment__info__data-box__classification {
  display: flex;
  align-items: center;
  gap: 5px;
}
.main__center__grid__left__comment__info__data-box__classification__star {
}

.main__center__grid__left__comment__info__data-box__texts {
  display: flex;
  gap: 10px;
  align-items: center;
}
.main__center__grid__left__comment__info__data-box__texts__name {
  color: #333;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
}
.main__center__grid__left__comment__info__data-box__texts__verified {
  display: flex;
  align-items: center;
  gap: 10px;
}
.main__center__grid__left__comment__info__data-box__texts__verified__icon {
}

.main__center__grid__left__comment__info__data-box__texts__verified__text {
  color: #5bb59a;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.main__center__grid__left__comment__content {
  color: #4d5254;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.main__center__grid__right {
  display: flex;
  flex-direction: column;
}

.main__center__grid__right__title {
  color: #000;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: capitalize;
  padding-bottom: 32px;
}

.main__center__grid__right__title--mob {
  display: none;
}

.main__center__grid__right__title--blue {
  color: #2c7ef8;
}
.main__center__grid__right__specifications {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  margin-bottom: 32px;
}
.main__center__grid__right__specifications__img-box {
  width: 134px;
  height: 134px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.main__center__grid__right__specifications__img-box__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.main__center__grid__right__specifications__texts {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: calc(100% - 134px - 24px);
}

.main__center__grid__right__specifications__texts__titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main__center__grid__right__specifications__texts__titles__title {
  color: #000;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  text-transform: capitalize;
}
.main__center__grid__right__specifications__texts__titles__prices {
  display: flex;
  align-items: center;
  gap: 10px;
}
.main__center__grid__right__specifications__texts__titles__prices__gray {
  color: #969696;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: line-through;
  text-transform: capitalize;
}
.main__center__grid__right__specifications__texts__titles__prices__blue {
  color: #2c7ef8;
  font-family: Manrope;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 30.8px */
  text-transform: capitalize;
}

.main__center__grid__right__specifications__texts__stars {
  display: flex;
  align-items: center;
  gap: 2px;
}

.main__center__grid__right__specifications__texts__stock {
  display: flex;
  align-items: center;
  gap: 16px;
}

.main__center__grid__right__specifications__texts__stock__text {
  color: #37465a;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.main__center__grid__right__specifications__texts__paragraph {
  color: #4d5254;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.main__center__grid__right__specifications__texts__paragraph--mobile {
  display: none;
}

.main__center__grid__right__caracteristics {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}
.main__center__grid__right__caracteristics__item {
  display: flex;
  align-items: center;
  gap: 12px;
}
.main__center__grid__right__caracteristics__item__check {
}
.main__center__grid__right__caracteristics__item__text {
  color: #4d5254;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

.main__center__grid__right__discount {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 10px;
  background: #edf3fd;
  margin-bottom: 32px;
}

.main__center__grid__right__discount__circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #2c7ef8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__center__grid__right__discount__text {
  color: #000;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.main__center__grid__right__discount__text--blue {
  color: #2c7ef8;
}

.main__center__grid__right__link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19.76px;
  border-radius: 50px;
  background: #59ae43;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 12px;
  transition-duration: 0.3s;
  text-decoration: none;
}

.main__center__grid__right__link:hover {
  background-color: #3f7b30;
}

.main__center__grid__right__link__text {
  color: #fff;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.main__center__grid__right__link__icon {
  transform: rotate(90deg) scale(1.6);
  filter: brightness(999);
}

.main__center__grid__right__payment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
  margin-bottom: 20px;
}

.main__center__grid__right__payment--mobile {
  display: none;
}
.main__center__grid__right__payment__text {
  color: #4d5254;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  text-transform: capitalize;
}
.main__center__grid__right__payment__separator {
  width: 1px;
  height: 16px;
  background: #cfcfcf;
}

.main__center__grid__right__payment__center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main__center__grid__right__payment__icons {
  display: flex;
  align-items: center;
  gap: 2px;
}

.main__center__grid__right__reject {
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 32px;
  color: #f82c2c;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  transition-duration: 0.3s;
}

.main__center__grid__right__reject:hover {
  color: #9b1c1c;
}

.main__center__grid__right__advise {
  display: flex;
  align-items: center;
  gap: 16px;
}

.main__center__grid__right__advise__text {
  color: #4d5254;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  width: calc(100% - 88px - 30px);
}

.main__center__grid__right__advise__img-box {
  width: 88px;
  height: 88px;
}

@media only screen and (max-width: 750px) {
  .main__center {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
  }
  .main__center__title {
    color: #000;
    text-align: center;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 44.8px */
    text-transform: capitalize;
    padding-top: 32px;
  }

  .main__center__subtitle {
    display: none;
  }
  .main__center__subtitle--mobile {
    display: block;
    color: #4d5254;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    text-transform: capitalize;
    padding-bottom: 24px;
  }

  .main__center__nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 24px;
  }

  .main__center__nav__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
  .main__center__nav__button__check {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #85bf55;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main__center__nav__button__check__icon {
    width: 12px;
    height: 12px;
  }

  .main__center__nav__button__check--current {
    color: #fff;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 15.4px */
    text-transform: capitalize;
    background-color: #2c7ef8;
  }

  .main__center__nav__button__check--not {
    color: #2c7ef8;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 15.4px */
    text-transform: capitalize;
    background-color: transparent;
    border: 1px solid #2c7ef8;
  }

  .main__center__nav__button__text--mobile {
    display: block;
    color: #000;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 13.2px */
    text-transform: capitalize;
  }

  .main__center__nav__button__text {
    display: none;
  }
  .main__center__nav__button__text--current {
    font-weight: 700;
  }

  .main__center__grid {
    display: flex;
    flex-direction: column;
    background: transparent;
    gap: 0px;
    padding: 0px;
    border-radius: 0px;
    margin-bottom: 32px;
  }
  .main__center__grid__left {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .main__center__grid__left__img-box {
    width: 100%;
    height: 320px;
    position: relative;
    max-width: 575px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .main__center__grid__left__img-box__img {
    display: none;
  }
  .main__center__grid__left__img-box__img--mob {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .main__center__grid__left__comment {
    display: none;
  }

  .main__center__grid__right {
    display: flex;
    flex-direction: column;
  }

  .main__center__grid__right__title {
    display: none;
  }

  .main__center__grid__right__title--mob {
    display: block;
    color: #000;
    text-align: center;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 33.6px */
    text-transform: capitalize;
    margin-bottom: 24px;
  }

  .main__center__grid__right__title--blue {
    color: #2c7ef8;
  }
  .main__center__grid__right__specifications {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  .main__center__grid__right__specifications__img-box {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  .main__center__grid__right__specifications__img-box__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .main__center__grid__right__specifications__texts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    width: calc(100% - 80px - 16px);
    min-height: 80px;
  }

  .main__center__grid__right__specifications__texts__titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main__center__grid__right__specifications__texts__titles__title {
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    text-transform: capitalize;
  }
  .main__center__grid__right__specifications__texts__titles__prices {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .main__center__grid__right__specifications__texts__titles__prices__gray {
    color: #969696;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: strikethrough;
    text-transform: capitalize;
  }
  .main__center__grid__right__specifications__texts__titles__prices__blue {
    color: #2c7ef8;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    text-transform: capitalize;
  }

  .main__center__grid__right__specifications__texts__stars {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .main__center__grid__right__specifications__texts__stars__star {
    width: 12px;
    height: 12px;
  }

  .main__center__grid__right__specifications__texts__stock {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .main__center__grid__right__specifications__texts__stock__text {
    color: #37465a;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .main__center__grid__right__specifications__texts__stock__circle {
    width: 12px;
    height: 12px;
  }
  .main__center__grid__right__specifications__texts__paragraph {
    display: none;
  }

  .main__center__grid__right__specifications__texts__paragraph--mobile {
    display: block;
    margin-bottom: 24px;
    color: #4d5254;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }

  .main__center__grid__right__caracteristics {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }
  .main__center__grid__right__caracteristics__item {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .main__center__grid__right__caracteristics__item__check {
    width: 16px;
    height: 16px;
  }
  .main__center__grid__right__caracteristics__item__text {
    color: #4d5254;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */
  }

  .main__center__grid__right__discount {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    border-radius: 10px;
    background: #edf3fd;
    margin-bottom: 24px;
  }

  .main__center__grid__right__discount__circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2c7ef8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main__center__grid__right__discount__circle__icon {
    width: 15px;
    height: 15px;
  }

  .main__center__grid__right__discount__text {
    color: #000;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    width: calc(100% - 16px - 16px);
  }

  .main__center__grid__right__discount__text--blue {
    color: #2c7ef8;
  }

  .main__center__grid__right__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 50px;
    background: #59ae43;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 12px;
    transition-duration: 0.3s;
    text-decoration: none;
  }

  .main__center__grid__right__link:hover {
    background-color: #3f7b30;
  }

  .main__center__grid__right__link__text {
    color: #fff;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .main__center__grid__right__link__icon {
    transform: rotate(90deg) scale(1.4);
    filter: brightness(999);
  }

  .main__center__grid__right__payment {
    display: none;
  }

  .main__center__grid__right__payment--mobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    padding: 8px 15px;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    margin-bottom: 12px;
  }

  .main__center__grid__right__payment--mobile__top {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .main__center__grid__right__payment--mobile__top__text {
    color: #4d5254;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    text-transform: capitalize;
  }

  .main__center__grid__right__payment--mobile__top__separator {
    width: 1px;
    height: 14px;
    background: #cfcfcf;
  }

  .main__center__grid__right__payment--mobile__top__right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .main__center__grid__right__payment--mobile__separator {
    background: #cfcfcf;
    height: 1px;
    width: 100%;
  }

  .main__center__grid__right__payment--mobile__bottom {
    display: flex;
    justify-content: center;
    gap: 2px;
    align-items: center;
  }

  .main__center__grid__right__reject {
    text-align: center;
    color: #f82c2c;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .main__center__grid__right__reject:hover {
    color: #9b1c1c;
  }

  .main__center__grid__right__advise {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .main__center__grid__right__advise__text {
    color: #4d5254;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    width: calc(100% - 48px - 16px);
  }

  .main__center__grid__right__advise__img-box {
    width: 48px;
    height: 48px;
  }

  .main__center__grid__right__advise__img-box__img {
    width: 100%;
    height: 100%;
  }
}
</style>
